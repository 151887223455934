import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getFormEntryById } from '../../utils/apiConstants';
import { toast } from 'react-toastify';
import Loader from '../../components/common/loader';

const ViewFormEntry = () => {
    const { form, id } = useParams();
    const navigate = useNavigate();
    const [isDetailLoading, setIsDetailLoading] = useState(true);
    const [formDetail, setFormDetail] = useState([]);

    const formatContent = content => {
        if (!content) return '-';

        return content.replace(/\r\n/g, '<br />').replace(/\n/g, '<br />');
    };

    const getFormValue = form => {
        switch (form) {
            case 'request_listing':
                return 1;
            case 'backlinks':
                return 2;
            case 'advertise':
                return 3;
            case 'profile_claim':
                return 4;
            case 'profile_contact':
                return 5;
            case 'profile_ads':
                return 6;
            case 'affiliate':
                return 7;

            default:
                return 0;
        }
    };

    const getFormName = form => {
        switch (form) {
            case 'request_listing':
                return 'Request Listing';
            case 'backlinks':
                return 'Backlinks';
            case 'advertise':
                return 'Advertise';
            case 'profile_claim':
                return 'Profile Claim';
            case 'profile_contact':
                return 'Profile Contact';
            case 'profile_ads':
                return 'Profile Ads';
            case 'affiliate':
                return 'Affiliate';
            case 'contact_us':
                return 'Contact Us';
            default:
                return '';
        }
    };

    const [value, setValue] = useState(getFormValue(form));

    const getFormDetailsById = async () => {
        setIsDetailLoading(true);
        try {
            const response = await getFormEntryById(form, id);
            if (response?.status === 200) {
                setFormDetail(response?.data?.data);
            }
        } catch (error) {
            toast.error(
                error?.response?.data?.message ||
                    error?.response?.data?.error ||
                    error?.message
            );
        }
        setIsDetailLoading(false);
    };

    useEffect(() => {
        if (id) {
            getFormDetailsById();
        }
    }, [id]);

    return (
        <Box>
            <Box className="content-header profile-module-wrapper">
                <Box className="content-header-left">
                    <Typography variant="h2" component="h2">
                        View Form Entry
                    </Typography>
                </Box>
            </Box>
            {isDetailLoading ? (
                <Box className="h-70vh">
                    <Loader />
                </Box>
            ) : (
                <Box className="content-layout mt-22 form-field-wrapper">
                    <Grid container spacing={3}>
                        <>
                            {(Array.isArray(formDetail) &&
                                formDetail.length > 0) ||
                            (typeof formDetail === 'object' &&
                                Object.keys(formDetail).length > 0) ? (
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="card-wrapper p-16">
                                        <Grid container spacing={3}>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Typography
                                                    variant="h3"
                                                    component="h3"
                                                    className="card-title"
                                                >
                                                    {getFormName(form)} Details
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Name
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {formDetail?.contact_name ||
                                                            formDetail?.request_listing_owner ||
                                                            formDetail?.backlink_name ||
                                                            formDetail?.advertise_name ||
                                                            formDetail?.profileclaim_name ||
                                                            formDetail?.profileads_name ||
                                                            formDetail?.affiliate_name ||
                                                            formDetail?.profilecontact_name ||
                                                            '-'}{' '}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Email
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {formDetail?.contact_email ||
                                                            formDetail?.request_listing_submitter_email ||
                                                            formDetail?.backlink_email ||
                                                            formDetail?.advertise_email ||
                                                            formDetail?.profileclaim_email ||
                                                            formDetail?.profilecontact_email ||
                                                            formDetail?.profileads_email ||
                                                            formDetail?.affiliate_email ||
                                                            '-'}{' '}
                                                    </Typography>
                                                </Box>
                                            </Grid>

                                            {(value === 0 || value === 6) && (
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Box className="field-wrapper">
                                                        <Typography
                                                            variant="body1"
                                                            component="label"
                                                        >
                                                            Note
                                                        </Typography>
                                                        <Typography
                                                            className="input-ui"
                                                            variant="body1"
                                                            component="div"
                                                            dangerouslySetInnerHTML={{
                                                                __html: formatContent(
                                                                    formDetail?.contact_note ||
                                                                        formDetail?.profileads_note ||
                                                                        '-'
                                                                ),
                                                            }}
                                                        ></Typography>
                                                    </Box>
                                                </Grid>
                                            )}
                                            {value === 1 && (
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Box className="field-wrapper">
                                                        <Typography
                                                            variant="body1"
                                                            component="label"
                                                        >
                                                            Overview
                                                        </Typography>
                                                        <Typography
                                                            className="input-ui"
                                                            variant="body1"
                                                            component="div"
                                                            dangerouslySetInnerHTML={{
                                                                __html: formatContent(
                                                                    formDetail?.request_listing_overview ||
                                                                        '-'
                                                                )
                                                            }}
                                                        ></Typography>
                                                    </Box>
                                                </Grid>
                                            )}
                                            {(value === 1 || value === 3) && (
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Box className="field-wrapper">
                                                        <Typography
                                                            variant="body1"
                                                            component="label"
                                                        >
                                                            Business
                                                        </Typography>
                                                        <Typography
                                                            className="input-ui"
                                                            variant="body1"
                                                            component="div"
                                                        >
                                                            {formDetail?.request_listing_business_name ||
                                                                formDetail?.advertise_business ||
                                                                '-'}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            )}
                                            {value === 0 ||
                                                (value === 5 && (
                                                    <Grid
                                                        item
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Box className="field-wrapper">
                                                            <Typography
                                                                variant="body1"
                                                                component="label"
                                                            >
                                                                Subject
                                                            </Typography>
                                                            <Typography
                                                                className="input-ui"
                                                                variant="body1"
                                                                component="div"
                                                            >
                                                                {formDetail?.contact_subject ||
                                                                    formDetail?.profilecontact_subject ||
                                                                    '-'}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                ))}
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Phone No
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {formDetail?.contact_phone ||
                                                            formDetail?.request_listing_phone ||
                                                            formDetail?.request_listing_submitter_phone ||
                                                            formDetail?.backlink_phone ||
                                                            formDetail?.advertise_phone ||
                                                            formDetail?.profileclaim_phone ||
                                                            formDetail?.profilecontact_phone ||
                                                            formDetail?.profileads_phone ||
                                                            formDetail?.affiliate_phone ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            {value === 6 && (
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Box className="field-wrapper">
                                                        <Typography
                                                            variant="body1"
                                                            component="label"
                                                        >
                                                            Profile Ads ID
                                                        </Typography>
                                                        <Typography
                                                            className="input-ui"
                                                            variant="body1"
                                                            component="div"
                                                        >
                                                            {formDetail?.profileads_profile_id ||
                                                                '-'}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            )}
                                            {value === 5 ||
                                                (value == 6 && (
                                                    <Grid
                                                        item
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Box className="field-wrapper">
                                                            <Typography
                                                                variant="body1"
                                                                component="label"
                                                            >
                                                                Profile URL
                                                            </Typography>
                                                            <Typography
                                                                className="input-ui"
                                                                variant="body1"
                                                                component="div"
                                                            >
                                                                {formDetail?.profilecontact_profile_url ||
                                                                formDetail?.profileads_profile_url ? (
                                                                    <Link
                                                                        to={
                                                                            formDetail?.profilecontact_profile_url ||
                                                                            formDetail?.profileads_profile_url
                                                                        }
                                                                        target="_blank"
                                                                    >
                                                                        {formDetail?.profilecontact_profile_url ||
                                                                            formDetail?.profileads_profile_url ||
                                                                            '-'}
                                                                    </Link>
                                                                ) : (
                                                                    '-'
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                ))}
                                            {value === 3 ||
                                                (value === 5 && (
                                                    <Grid
                                                        item
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Box className="field-wrapper">
                                                            <Typography
                                                                variant="body1"
                                                                component="label"
                                                            >
                                                                Message
                                                            </Typography>
                                                            <Typography
                                                                className="input-ui"
                                                                variant="body1"
                                                                component="div"
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                    formatContent(formDetail?.advertise_message ||
                                                                        formDetail?.profilecontact_message ||
                                                                        '-')
                                                                }}
                                                            ></Typography>
                                                        </Box>
                                                    </Grid>
                                                ))}
                                            {value === 4 && (
                                                <>
                                                    <Grid
                                                        item
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Box className="field-wrapper">
                                                            <Typography
                                                                variant="body1"
                                                                component="label"
                                                            >
                                                                Profile Business
                                                                Name
                                                            </Typography>
                                                            <Typography
                                                                className="input-ui"
                                                                variant="body1"
                                                                component="div"
                                                            >
                                                                {formDetail?.profileclaim_profile_business_name ||
                                                                    '-'}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Box className="field-wrapper">
                                                            <Typography
                                                                variant="body1"
                                                                component="label"
                                                            >
                                                                Claim Profile Id
                                                            </Typography>
                                                            <Typography
                                                                className="input-ui"
                                                                variant="body1"
                                                                component="div"
                                                            >
                                                                {formDetail?.profileclaim_profile_id ||
                                                                    '-'}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Box className="field-wrapper">
                                                            <Typography
                                                                variant="body1"
                                                                component="label"
                                                            >
                                                                Claim Profile
                                                                URL
                                                            </Typography>
                                                            <Typography
                                                                className="input-ui"
                                                                variant="body1"
                                                                component="div"
                                                            >
                                                                {formDetail?.profileclaim_profile_url ? (
                                                                    <Link
                                                                        to={
                                                                            formDetail?.profileclaim_profile_url
                                                                        }
                                                                        target="_blank"
                                                                    >
                                                                        {formDetail?.profileclaim_profile_url ||
                                                                            '-'}
                                                                    </Link>
                                                                ) : (
                                                                    '-'
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </>
                                            )}
                                            {value === 2 && (
                                                <>
                                                    <Grid
                                                        item
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Box className="field-wrapper">
                                                            <Typography
                                                                variant="body1"
                                                                component="label"
                                                            >
                                                                Backlink Package
                                                            </Typography>
                                                            <Typography
                                                                className="input-ui"
                                                                variant="body1"
                                                                component="div"
                                                            >
                                                                {
                                                                    formDetail?.backlink_package
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Box className="field-wrapper">
                                                            <Typography
                                                                variant="body1"
                                                                component="label"
                                                            >
                                                                Backlink Details
                                                            </Typography>
                                                            <Typography
                                                                className="input-ui"
                                                                variant="body1"
                                                                component="div"
                                                            >
                                                                {
                                                                    formDetail?.backlink_details
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </>
                                            )}
                                            {value === 1 && (
                                                <>
                                                    <Grid
                                                        item
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Box className="field-wrapper">
                                                            <Typography
                                                                variant="body1"
                                                                component="label"
                                                            >
                                                                Website
                                                            </Typography>
                                                            <Typography
                                                                className="input-ui"
                                                                variant="body1"
                                                                component="div"
                                                            >
                                                                {formDetail?.request_listing_website ? (
                                                                    <Link
                                                                        to={
                                                                            formDetail?.request_listing_website
                                                                        }
                                                                        target="_blank"
                                                                    >
                                                                        {formDetail?.request_listing_website ||
                                                                            '-'}
                                                                    </Link>
                                                                ) : (
                                                                    '-'
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Box className="field-wrapper">
                                                            <Typography
                                                                variant="body1"
                                                                component="label"
                                                            >
                                                                Request Listing
                                                                Service 1
                                                            </Typography>
                                                            <Typography
                                                                className="input-ui"
                                                                variant="body1"
                                                                component="div"
                                                            >
                                                                {formDetail?.request_listing_service_1 ||
                                                                    '-'}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Box className="field-wrapper">
                                                            <Typography
                                                                variant="body1"
                                                                component="label"
                                                            >
                                                                Request Listing
                                                                Service 2
                                                            </Typography>
                                                            <Typography
                                                                className="input-ui"
                                                                variant="body1"
                                                                component="div"
                                                            >
                                                                {formDetail?.request_listing_service_2 ||
                                                                    '-'}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Box className="field-wrapper">
                                                            <Typography
                                                                variant="body1"
                                                                component="label"
                                                            >
                                                                Request Listing
                                                                Service 3
                                                            </Typography>
                                                            <Typography
                                                                className="input-ui"
                                                                variant="body1"
                                                                component="div"
                                                            >
                                                                {formDetail?.request_listing_service_3 ||
                                                                    '-'}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Box className="field-wrapper">
                                                            <Typography
                                                                variant="body1"
                                                                component="label"
                                                            >
                                                                Business Logo
                                                            </Typography>
                                                            <Typography
                                                                className="input-ui"
                                                                variant="body1"
                                                                component="div"
                                                            >
                                                                {formDetail?.request_listing_business_logo ? (
                                                                    <img
                                                                        src={
                                                                            formDetail?.request_listing_business_logo
                                                                        }
                                                                        alt="business-logo"
                                                                        style={{
                                                                            width: '250px',
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    '-'
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Box className="field-wrapper">
                                                            <Typography
                                                                variant="body1"
                                                                component="label"
                                                            >
                                                                Address Street
                                                            </Typography>
                                                            <Typography
                                                                className="input-ui"
                                                                variant="body1"
                                                                component="div"
                                                            >
                                                                {formDetail?.request_listing_address_street ||
                                                                    '-'}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Box className="field-wrapper">
                                                            <Typography
                                                                variant="body1"
                                                                component="label"
                                                            >
                                                                Address State
                                                            </Typography>
                                                            <Typography
                                                                className="input-ui"
                                                                variant="body1"
                                                                component="div"
                                                            >
                                                                {formDetail?.request_listing_address_state ||
                                                                    '-'}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Box className="field-wrapper">
                                                            <Typography
                                                                variant="body1"
                                                                component="label"
                                                            >
                                                                Address City
                                                            </Typography>
                                                            <Typography
                                                                className="input-ui"
                                                                variant="body1"
                                                                component="div"
                                                            >
                                                                {formDetail?.request_listing_address_city ||
                                                                    '-'}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Box className="field-wrapper">
                                                            <Typography
                                                                variant="body1"
                                                                component="label"
                                                            >
                                                                Address County
                                                            </Typography>
                                                            <Typography
                                                                className="input-ui"
                                                                variant="body1"
                                                                component="div"
                                                            >
                                                                {formDetail?.request_listing_address_county ||
                                                                    '-'}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Box className="field-wrapper">
                                                            <Typography
                                                                variant="body1"
                                                                component="label"
                                                            >
                                                                Address Zip
                                                            </Typography>
                                                            <Typography
                                                                className="input-ui"
                                                                variant="body1"
                                                                component="div"
                                                            >
                                                                {formDetail?.request_listing_address_zip ||
                                                                    '-'}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </>
                                            )}
                                            {value === 7 && (
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Box className="field-wrapper">
                                                        <Typography
                                                            variant="body1"
                                                            component="label"
                                                        >
                                                            Questions
                                                        </Typography>
                                                        <Typography
                                                            className="input-ui"
                                                            variant="body1"
                                                            component="div"
                                                        >
                                                            {formDetail?.affiliate_questions ||
                                                                '-'}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Box>
                                </Grid>
                            ) : (
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="card-wrapper p-16">
                                        <Grid container spacing={3}>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                                sx={{
                                                    margin: '25px 0',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <Typography
                                                    variant="h3"
                                                    component="h3"
                                                    className="card-title"
                                                >
                                                    Detail Not Found.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            )}
                        </>
                    </Grid>
                    <Box className="footer-wrapper">
                        <Box className="btn-wrapper">
                            <Button
                                className="btn secondary-btn"
                                onClick={() =>
                                    navigate('/form-entries', {
                                        state: { formName: getFormValue(form) },
                                    })
                                }
                            >
                                Back
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default ViewFormEntry;
