import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import CreateIcon from '@mui/icons-material/Create';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import MyAccountImage from '../../assets/images/my-account.png';
import { toast } from 'react-toastify';
import { changePassword, updateProfile } from '../../utils/apiConstants';
import { CircularProgress, InputAdornment } from '@mui/material';

const passwordValidationSchema = Yup.string()
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        'Password must contain at least one special character'
    )
    .required('Password is required');

const validationSchema = Yup.object({
    displayName: Yup.string()
        .required('Display Name is required')
        .matches(
            /^(?![-\s]*$)[a-zA-Z0-9 ]+$/,
            'Display Name cannot be empty spaces , hyphens, or include special characters'
        ),
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    oldpassword: Yup.string().test(
        'check-oldpassword',
        'Old Password is required.',
        function (value) {
            const { newpassword, confirmpassword } = this.parent;
            if (newpassword || confirmpassword) {
                return !!value;
            }
            return true;
        }
    ),
    newpassword: passwordValidationSchema.test(
        'check-newpassword',
        'New Password is required.',
        function (value) {
            const { oldpassword, confirmpassword } = this.parent;
            if (oldpassword || confirmpassword) {
                return !!value;
            }
            return true;
        }
    ),
    confirmpassword: Yup.string()
        .test(
            'check-confirmpassword',
            'Confirm Password is required.',
            function (value) {
                const { oldpassword, newpassword } = this.parent;
                if (oldpassword || newpassword) {
                    return !!value;
                }
                return true;
            }
        )
        .oneOf(
            [Yup.ref('newpassword'), null],
            'New Password and Confirm password must match'
        ),
});

const MyAccount = () => {
    const navigate = useNavigate();
    const [showPasswords, setShowPasswords] = useState([false, false, false]);
    const [loader, setLoader] = useState(false);
    const [image, setImage] = useState(MyAccountImage);
    const userDetail = localStorage.getItem('user');
    const parsedUser = userDetail ? JSON.parse(userDetail) : null;
    const [user, setUser] = useState(parsedUser);

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        toast.success('Logged out Successfully!!');
        window.location.href = `${process.env.REACT_APP_FRONTEND_SITE_URL}/logout`;
    };

    const updateUserDetails = async values => {
        setLoader(true);
        try {
            const data = new FormData();

            if (values.displayName || values.email) {
                data.append('displayname', values.displayName);
                data.append('email', values.email);
                const response = await updateProfile(data);

                if (response?.status == 200) {
                    localStorage.setItem(
                        'user',
                        JSON.stringify(response?.data?.data)
                    );
                    setUser(response?.data?.data);
                    if (!values?.newpassword) {
                        toast.success(response?.data?.message);
                        setTimeout(() => {
                            window.location.href = '/my-account';
                        }, 2000);
                    }
                }
            }
            if (values?.newpassword) {
                data.append('old_password', values.oldpassword);
                data.append('new_password', values.newpassword);
                const result = await changePassword(data);
                if (result?.status === 200) {
                    toast.success(result?.data?.message);
                    logout();
                    return;
                }
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.detail);
        }
        setLoader(false);
    };

    const togglePasswordVisibility = index => {
        setShowPasswords(prev => {
            const newShowPasswords = [...prev];
            newShowPasswords[index] = !newShowPasswords[index];
            return newShowPasswords;
        });
    };

    const handleImageChange = event => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
    };

    return (
        <Formik
            initialValues={{
                displayName: user?.displayname || '',
                email: user?.email || '',
                oldpassword: '',
                newpassword: '',
                confirmpassword: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
                updateUserDetails(values);
                // resetForm({
                //     values: {
                //         ...values,
                //         oldpassword: '',
                //         newpassword: '',
                //         confirmpassword: '',
                //     },
                // });
            }}
        >
            {({ values, handleChange, handleBlur }) => (
                <Form>
                    <Box className="content-header profile-module-wrapper">
                        <Box className="content-header-left">
                            <Typography variant="h2" component="h2">
                                My Account
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="content-layout form-field-wrapper">
                        <Grid container spacing={3}>
                            <Grid item lg={4} md={12} sm={12} xs={12}>
                                <Box className="card-wrapper h-100">
                                    <Stack spacing={3} className="p-16">
                                        <Box>
                                            <Box className="my-account-wrapper">
                                                <img
                                                    src={image}
                                                    alt="My Account"
                                                    className="myaccount-img"
                                                />
                                                <Box
                                                    className="upload-img"
                                                    onClick={triggerFileInput}
                                                >
                                                    <CreateIcon />
                                                </Box>
                                                <input
                                                    type="file"
                                                    id="fileInput"
                                                    style={{ display: 'none' }}
                                                    onChange={handleImageChange}
                                                    accept="image/*"
                                                />
                                            </Box>
                                        </Box>
                                        <Box className="field-wrapper">
                                            <Typography
                                                variant="body1"
                                                component="label"
                                            >
                                                ID
                                            </Typography>
                                            <TextField
                                                className="input-ui"
                                                value={user?.id}
                                                disabled
                                                fullWidth
                                            />
                                        </Box>
                                        <Box className="field-wrapper">
                                            <Typography
                                                variant="body1"
                                                component="label"
                                            >
                                                Username
                                            </Typography>
                                            <TextField
                                                className="input-ui"
                                                value={user?.username}
                                                disabled
                                                fullWidth
                                            />
                                        </Box>
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <Box className="card-wrapper h-100">
                                    <Stack spacing={3} className="p-16">
                                        <Typography
                                            variant="h3"
                                            component="h3"
                                            className="card-title"
                                        >
                                            Personal Details
                                        </Typography>
                                        <Box className="field-wrapper">
                                            <Typography
                                                variant="body1"
                                                component="label"
                                            >
                                                Display Name
                                            </Typography>
                                            <Field
                                                as={TextField}
                                                name="displayName"
                                                className="input-ui"
                                                fullWidth
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.displayName}
                                            />
                                            <ErrorMessage
                                                name="displayName"
                                                className="error-message"
                                                component="div"
                                            />
                                        </Box>
                                        <Box className="field-wrapper">
                                            <Typography
                                                variant="body1"
                                                component="label"
                                            >
                                                Email
                                            </Typography>
                                            <Field
                                                as={TextField}
                                                name="email"
                                                className="input-ui"
                                                fullWidth
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                            />
                                            <ErrorMessage
                                                name="email"
                                                className="error-message"
                                                component="div"
                                            />
                                        </Box>
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <Box className="card-wrapper h-100">
                                    <Stack spacing={3} className="p-16">
                                        <Typography
                                            variant="h3"
                                            component="h3"
                                            className="card-title"
                                        >
                                            Change Password
                                        </Typography>
                                        {[
                                            'Old Password',
                                            'New Password',
                                            'Confirm Password',
                                        ].map((label, index) => (
                                            <Box
                                                className="field-wrapper"
                                                key={index}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    component="label"
                                                >
                                                    {label}
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <Field
                                                        as={TextField}
                                                        name={label
                                                            .replace(' ', '')
                                                            .toLowerCase()}
                                                        placeholder={`Enter ${label}`}
                                                        type={
                                                            showPasswords[index]
                                                                ? 'text'
                                                                : 'password'
                                                        }
                                                        fullWidth
                                                        className="input-ui"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={() =>
                                                                            togglePasswordVisibility(
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        {showPasswords[
                                                                            index
                                                                        ] ? (
                                                                            <VisibilityRoundedIcon />
                                                                        ) : (
                                                                            <VisibilityOffRoundedIcon />
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                    <ErrorMessage
                                                        name={label
                                                            .replace(' ', '')
                                                            .toLowerCase()}
                                                        component="div"
                                                        className="error-message"
                                                    />
                                                </Box>
                                            </Box>
                                        ))}
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box className="footer-wrapper">
                            <Box className="btn-wrapper">
                                <Button
                                    type="submit"
                                    className="btn primary-btn"
                                    disabled={loader}
                                    endIcon={
                                        loader && (
                                            <CircularProgress
                                                sx={{
                                                    color: 'var(--white-color)',
                                                }}
                                                size={16}
                                            />
                                        )
                                    }
                                >
                                    Save
                                </Button>
                                <Button
                                    className="btn secondary-btn"
                                    onClick={() => navigate('/')}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default MyAccount;
