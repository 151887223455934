import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import createTheme from '@mui/material/styles/createTheme';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { toast } from 'react-toastify';
import DeleteTrash from '../../assets/images/icons/delete.svg';
import SwitchField from '../../components/common/switchField';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
    deleteProfile,
    editProfile,
    getCategoryList,
    getProfileDetailBySlug,
    getProfiles,
    getStateList,
} from '../../utils/apiConstants';
import {
    getFormattedDateTime,
    handleSelectChange,
    pagintaionPageCount,
    transformProfileEditFormData,
} from '../../utils/constants';
import Pagination from '../../components/common/pagination';
import Loader from '../../components/common/loader';
import { useDebounce } from '../../utils/useDebounce';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CircularProgress } from '@mui/material';
import { ArrowDownward, ArrowUpward, SwapVert } from '@mui/icons-material';

const theme = createTheme();

// TABBING UI FOR TABLE
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Profiles = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);
    const [profiles, setProfiles] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [totalAllCount, setTotalAllCount] = useState(0);
    const [showPerPage, setShowPerPage] = useState(10);
    const [selectedPage, setSelectedPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [open, setOpen] = useState(false);
    const [isOpen, isSetOpen] = useState(false);
    const [statusCounts, setStatusCounts] = useState({});
    const [value, setValue] = useState(0);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const debouncedSearchTerm = useDebounce(searchValue, 500);
    const [selectedSlugs, setSelectedSlugs] = useState([]);
    const [headerChecked, setHeaderChecked] = useState(false);
    const [isDetailLoading, setIsDetailLoading] = useState(true);
    const [profileDetail, setProfileDetail] = useState([]);
    const [categoryOption, setCategoryOption] = useState([]);
    const [categoryLoader, setCategoryLoader] = useState(true);
    const [categorySearch, setCategorySearch] = useState('');
    const [stateOption, setStateOption] = useState([]);
    const [StateLoader, setStateLoader] = useState(true);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortBy, setSortBy] = useState(null);
    const [editSlug, setEditSlug] = useState(null);
    const userDetail = localStorage.getItem('user');
    const parsedUser = userDetail ? JSON.parse(userDetail) : null;
    const [user, setUser] = useState(parsedUser);

    const handleHeaderCheckboxChange = () => {
        if (headerChecked) {
            setSelectedSlugs([]);
        } else {
            const allSlugs = profiles?.map(profile => profile.id);
            setSelectedSlugs(allSlugs);
        }
        setHeaderChecked(!headerChecked);
    };

    const handleInputChange = newValue => {
        setCategorySearch(newValue);
    };

    const handleRowCheckboxChange = id => {
        if (selectedSlugs.includes(id)) {
            setSelectedSlugs(selectedSlugs.filter(item => item !== id));
        } else {
            setSelectedSlugs([...selectedSlugs, id]);
        }
    };

    useEffect(() => {
        if (selectedSlugs?.length < 1) {
            setHeaderChecked(false);
        }
    }, [selectedSlugs]);

    const getStatus = value => {
        switch (value) {
            case 1:
                return 'publish';
            case 2:
                return 'pending';
            case 3:
                return 'draft';
            case 4:
                return 'private';

            default:
                return 'all';
        }
    };

    const deleteProfileBySlug = async () => {
        setLoader(true);
        try {
            if (selectedSlugs) {
                const promises = selectedSlugs.map(async id => {
                    const response = await deleteProfile(id);
                    return response;
                });
                const responses = await Promise.all(promises);

                if (responses?.[0]?.data?.status == 200) {
                    setSelectedPage(1);
                    toast.success(responses?.[0]?.data?.message);
                    setOpen(false);
                    setHeaderChecked(false);
                }
            }
        } catch (error) {
            toast.error(
                error?.response?.data?.message ||
                    error?.response?.data?.detail ||
                    error.message
            );
        }
        setLoader(false);
    };

    const getProfileList = async (
        newSortOrder = sortOrder,
        column = sortBy
    ) => {
        setLoader(true);
        try {
            const response = await getProfiles(
                getStatus(value),
                selectedPage > 0 ? selectedPage : 1,
                showPerPage,
                searchValue,
                sortBy || column
                    ? (newSortOrder || sortOrder) === 'asc'
                        ? column || sortBy
                        : `-` + column || sortBy
                    : sortBy
            );
            if (response?.status == 200) {
                setProfiles(response?.data?.results?.data);
                setTotalCount(response?.data?.count || 0);
                setTotalAllCount(response?.data?.results?.total_counts || 0);
                setStatusCounts(response?.data?.results?.status_counts);
                setTotalPage(
                    Math.ceil(
                       response?.data?.count / showPerPage) || 0
                );
            }
        } catch (error) {
            toast.error(
                error?.response?.data?.message ||
                    error?.response?.data?.detail ||
                    error.message
            );
        }
        setLoader(false);
    };

    const fetchData = pageSize => {
        setSelectedPage(1);
        setShowPerPage(pageSize);
    };

    useEffect(() => {
        getProfileList();
    }, [debouncedSearchTerm, showPerPage, selectedPage, value]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSelectedPage(1);
    };

    const trashModalOpen = () => setOpen(true);
    const trashModalClose = () => setOpen(false);

    const handleEditDrawer = id => {
        isSetOpen(!isOpen);
        setEditSlug(id);
        getProfileDetailsBySlug(id);
        getCategoryOption();
        getStateOption();
    };

    const getProfileDetailsBySlug = async id => {
        setIsDetailLoading(true);
        try {
            const response = await getProfileDetailBySlug(id);
            if (response?.status === 200) {
                setProfileDetail(response?.data?.data);
                setCategorySearch(
                    response?.data?.data?.category_name?.[0]?.name
                );
            }
        } catch (error) {
            toast.error(error.response?.data?.message || error.message);
        }
        setIsDetailLoading(false);
    };

    const getCategoryOption = async () => {
        setCategoryLoader(true);
        try {
            const response = await getCategoryList(categorySearch);
            if (response?.status == 200) {
                const options = response?.data?.results?.data?.map(item => ({
                    value: item.id,
                    label: item.name,
                }));
                setCategoryOption(options);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || error.message);
        }
        setCategoryLoader(false);
    };

    const debouncedFetchCategoryOptions = useDebounce(categorySearch, 300);

    useEffect(() => {
        if (user?.role !== 'user') {
            getCategoryOption();
        }
    }, [debouncedFetchCategoryOptions]);

    const getStateOption = async () => {
        setStateLoader(true);
        try {
            const response = await getStateList();
            if (response?.status == 200) {
                const options = response?.data?.results?.data.map(item => ({
                    value: item.name,
                    label: item.name,
                }));
                setStateOption(options);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || error.message);
        }
        setStateLoader(false);
    };

    const handleSwitchChange = (fieldName, value, setFieldValue) => {
        setFieldValue(fieldName, value);
    };

    const [openRow, setOpenRow] = useState(null);
    const handleRowClick = index => {
        setOpenRow(prevOpenRow => (prevOpenRow === index ? null : index));
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        slug: Yup.string().required('Slug is required'),
        category_name: Yup.array()
            .min(1, 'Select at least one category')
            .nullable(),
        meta_title: Yup.string().required('Meta Title is required'),
    });

    const handleSort = column => {
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
        getProfileList(newSortOrder, column);
    };

    const saveProfileDetail = async values => {
        setLoader(true);
        try {
            const transformedData = transformProfileEditFormData(values);
            const data = new FormData();
            data.append('title', transformedData?.title);
            data.append(
                'add_content',
                JSON.stringify(transformedData?.add_content)
            );
            data.append('category_name', transformedData?.category_name);
            data.append('slug', transformedData?.slug);
            data.append('info', JSON.stringify(transformedData?.info));
            data.append('address', JSON.stringify(transformedData?.address));
            data.append('meta_title', transformedData?.meta_title);
            data.append('meta_description', transformedData?.meta_description);
            data.append('meta_keywords', transformedData?.meta_keywords);
            // data.append('author', transformedData?.author);
            const responses = await editProfile(editSlug, data);
            if (responses?.data?.status == 200) {
                toast.success(responses?.data?.message);
            }
            setEditSlug(null);
            isSetOpen(false);
            getProfileList();
        } catch (error) {
            toast.error(error?.response?.data?.message || error.message);
        }
        setLoader(false);
    };

    const getSortIcon = column => {
        if (sortOrder === 'asc' && sortBy === column) {
            return <ArrowUpward size={20} />;
        } else if (sortOrder === 'desc' && sortBy === column) {
            return <ArrowDownward size={20} />;
        } else {
            return <SwapVert size={20} />;
        }
    };

    return (
        <>
            <Box className="content-header profile-module-wrapper">
                <Box className="content-header-left">
                    <Typography variant="h2" component="h2">
                        Profiles
                    </Typography>
                    <Button
                        className="outline-btn btn"
                        onClick={() => navigate('/add-profile')}
                    >
                        Add New
                    </Button>
                </Box>
                <Box className="content-header-right">
                    <Box className="item-badge">{totalAllCount} items</Box>
                    <Box variant="div" component="div" className="search-bar">
                        <SearchIcon />
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Search Profile"
                            onChange={e => {
                                setSearchValue(e.target.value);
                                setSelectedPage(1);
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box className="content-layout">
                <Box className="tab-ui-wrapper">
                    <Tabs
                        className="tab-button-wrapper"
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        aria-label="basic tabs example"
                    >
                        <Tab
                            label={`All (${totalAllCount || 0})`}
                            {...a11yProps(0)}
                        />
                        <Tab
                            label={`Published (${statusCounts?.publish || 0})`}
                            {...a11yProps(1)}
                        />
                        <Tab
                            label={`Pending (${statusCounts?.pending || 0})`}
                            {...a11yProps(2)}
                        />
                        <Tab
                            label={`Draft (${statusCounts?.draft || 0})`}
                            {...a11yProps(3)}
                        />
                        <Tab
                            label={`private (${statusCounts?.private || 0})`}
                            {...a11yProps(4)}
                        />
                    </Tabs>
                    <CustomTabPanel value={value} index={value}>
                        <Box className="card-wrapper border-1-5">
                            <TableContainer className="profile-table-ui">
                                <Table stickyHeader aria-label="simple table">
                                    <TableHead className="bg-primary-10">
                                        <TableRow className="hide-thead">
                                            <TableCell className="table-checkbox-width">
                                                <Checkbox
                                                    className="checkbox-ui"
                                                    checked={headerChecked}
                                                    onChange={
                                                        handleHeaderCheckboxChange
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell
                                                onClick={() => {
                                                    setSortBy('title');
                                                    handleSort('title');
                                                }}
                                            >
                                                Title {getSortIcon('title')}
                                            </TableCell>
                                            <TableCell
                                                onClick={() => {
                                                    setSortBy('category');
                                                    handleSort('category');
                                                }}
                                            >
                                                Category{' '}
                                                {getSortIcon('category')}
                                            </TableCell>
                                            <TableCell
                                                onClick={() => {
                                                    setSortBy('meta_title');
                                                    handleSort('meta_title');
                                                }}
                                            >
                                                Meta Title{' '}
                                                {getSortIcon('meta_title')}
                                            </TableCell>
                                            <TableCell
                                                onClick={() => {
                                                    setSortBy('slug');
                                                    handleSort('slug');
                                                }}
                                            >
                                                Permalink {getSortIcon('slug')}
                                            </TableCell>
                                            <TableCell
                                                onClick={() => {
                                                    setSortBy('author');
                                                    handleSort('author');
                                                }}
                                            >
                                                Author {getSortIcon('author')}
                                            </TableCell>
                                            <TableCell
                                                onClick={() => {
                                                    setSortBy('updated_at');
                                                    handleSort('updated_at');
                                                }}
                                            >
                                                Last Modified{' '}
                                                {getSortIcon('updated_at')}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loader ? (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={7}
                                                    align={'center'}
                                                >
                                                    <Loader />
                                                </TableCell>
                                            </TableRow>
                                        ) : profiles?.length > 0 ? (
                                            profiles?.map((row, index) => (
                                                <React.Fragment key={index}>
                                                    <TableRow
                                                        onClick={e => {
                                                            if (
                                                                isSmallScreen &&
                                                                e.target
                                                                    .type !==
                                                                    'checkbox'
                                                            ) {
                                                                handleRowClick(
                                                                    index
                                                                );
                                                            }
                                                        }}
                                                        className={
                                                            openRow === index
                                                                ? 'open'
                                                                : ''
                                                        }
                                                    >
                                                        <TableCell className="table-checkbox-width">
                                                            <Checkbox
                                                                className="checkbox-ui"
                                                                checked={selectedSlugs.includes(
                                                                    row?.id
                                                                )}
                                                                onChange={() =>
                                                                    handleRowCheckboxChange(
                                                                        row?.id
                                                                    )
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell className="link-title-text">
                                                            <Box className="show-text-arrow-content">
                                                                <Link
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `/view-profile/${row?.id}`
                                                                        )
                                                                    }
                                                                    className="MuiLink-root fw-600 cursor-pointer"
                                                                >
                                                                    {row?.title}
                                                                </Link>
                                                                {openRow ===
                                                                index ? (
                                                                    <KeyboardArrowUpIcon />
                                                                ) : (
                                                                    <KeyboardArrowDownIcon />
                                                                )}
                                                            </Box>
                                                            <Stack className="table-action-button-wrapper">
                                                                <Link
                                                                    onClick={() => {
                                                                        navigate(
                                                                            `/view-profile/${row?.id}`
                                                                        );
                                                                    }}
                                                                    className="cursor-pointer"
                                                                >
                                                                    {' '}
                                                                    View{' '}
                                                                </Link>
                                                                {user?.role !==
                                                                    'user' && (
                                                                    <>
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="span"
                                                                            className="divider-span"
                                                                        ></Typography>
                                                                        <Link
                                                                            className="cursor-pointer"
                                                                            onClick={() =>
                                                                                navigate(
                                                                                    `/edit-profile/${row?.id}`
                                                                                )
                                                                            }
                                                                        >
                                                                            {' '}
                                                                            Edit{' '}
                                                                        </Link>
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="span"
                                                                            className="divider-span"
                                                                        ></Typography>
                                                                        <Link
                                                                            className="cursor-pointer"
                                                                            onClick={() => {
                                                                                handleEditDrawer(
                                                                                    row?.id
                                                                                );
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            Quick
                                                                            Edit{' '}
                                                                        </Link>
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="span"
                                                                            className="divider-span"
                                                                        ></Typography>
                                                                        <Link
                                                                            className="trash cursor-pointer"
                                                                            onClick={() => {
                                                                                setSelectedSlugs(
                                                                                    [
                                                                                        ...selectedSlugs,
                                                                                        row?.id,
                                                                                    ]
                                                                                );
                                                                                trashModalOpen();
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            Trash{' '}
                                                                        </Link>
                                                                    </>
                                                                )}
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                            className="hide-td"
                                                        >
                                                            {row?.category_name
                                                                ?.length > 0
                                                                ? row?.category_name
                                                                      .map(
                                                                          (
                                                                              role,
                                                                              index
                                                                          ) =>
                                                                              `${role}${
                                                                                  index <
                                                                                  row
                                                                                      ?.category_name
                                                                                      ?.length -
                                                                                      1
                                                                                      ? ', '
                                                                                      : ''
                                                                              }`
                                                                      )
                                                                      .join('')
                                                                : '-'}
                                                        </TableCell>
                                                        <TableCell className="hide-td">
                                                            {row?.meta_title}
                                                        </TableCell>
                                                        <TableCell className="hide-td">
                                                            <Link
                                                                onClick={() =>
                                                                    window.open(
                                                                        `${process.env.REACT_APP_FRONTEND_SITE_URL}/${row?.slug}`,
                                                                        '_blank'
                                                                    )
                                                                }
                                                                className="cursor-pointer"
                                                            >
                                                                {row?.slug}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell className="hide-td">
                                                            {row?.author_role
                                                                ?.length > 0
                                                                ? row.author_role
                                                                      .map(
                                                                          (
                                                                              role,
                                                                              index
                                                                          ) =>
                                                                              `${role}${
                                                                                  index <
                                                                                  row
                                                                                      .author_role
                                                                                      .length -
                                                                                      1
                                                                                      ? ', '
                                                                                      : ''
                                                                              }`
                                                                      )
                                                                      .join('')
                                                                : '-'}
                                                        </TableCell>
                                                        <TableCell className="hide-td">
                                                            {row?.updated_at &&
                                                                getFormattedDateTime(
                                                                    row?.updated_at
                                                                )}
                                                        </TableCell>
                                                    </TableRow>
                                                    {openRow === index && (
                                                        <TableRow className="accordion-content">
                                                            <TableCell
                                                                colSpan={2}
                                                            >
                                                                <Box className="accordion-box">
                                                                    <Stack
                                                                        className=""
                                                                        spacing={
                                                                            1
                                                                        }
                                                                    >
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="p"
                                                                            className="fw-600"
                                                                        >
                                                                            Title:
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                            >
                                                                                <Link
                                                                                    onClick={() =>
                                                                                        navigate(
                                                                                            `/view-profile/${row?.id}`
                                                                                        )
                                                                                    }
                                                                                    className="MuiLink-root fw-600 cursor-pointer"
                                                                                >
                                                                                    {
                                                                                        row.title
                                                                                    }
                                                                                </Link>
                                                                            </Typography>
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="p"
                                                                            className="fw-600"
                                                                        >
                                                                            Category:
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                            >
                                                                                {' '}
                                                                                {row
                                                                                    ?.category_name
                                                                                    ?.length >
                                                                                0
                                                                                    ? row?.category_name
                                                                                          .map(
                                                                                              (
                                                                                                  role,
                                                                                                  index
                                                                                              ) =>
                                                                                                  `${role}${
                                                                                                      index <
                                                                                                      row
                                                                                                          ?.category_name
                                                                                                          ?.length -
                                                                                                          1
                                                                                                          ? ', '
                                                                                                          : '-'
                                                                                                  }`
                                                                                          )
                                                                                          .join(
                                                                                              ''
                                                                                          )
                                                                                    : ''}
                                                                            </Typography>
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="p"
                                                                            className="fw-600"
                                                                        >
                                                                            Meta
                                                                            Title:
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                            >
                                                                                {
                                                                                    row?.meta_title
                                                                                }
                                                                            </Typography>
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="p"
                                                                            className="fw-600"
                                                                        >
                                                                            Permalink:
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                            >
                                                                                <Link
                                                                                    className="cursor-pointer"
                                                                                    onClick={() =>
                                                                                        window.open(
                                                                                            `${process.env.REACT_APP_FRONTEND_SITE_URL}/${row?.slug}`,
                                                                                            '_blank'
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        row?.slug
                                                                                    }
                                                                                </Link>
                                                                            </Typography>
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="p"
                                                                            className="fw-600"
                                                                        >
                                                                            Author:
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                            >
                                                                                {row
                                                                                    ?.author_role
                                                                                    ?.length >
                                                                                0
                                                                                    ? row.author_role
                                                                                          .map(
                                                                                              (
                                                                                                  role,
                                                                                                  index
                                                                                              ) =>
                                                                                                  `${role}${
                                                                                                      index <
                                                                                                      row
                                                                                                          .author_role
                                                                                                          .length -
                                                                                                          1
                                                                                                          ? ', '
                                                                                                          : ''
                                                                                                  }`
                                                                                          )
                                                                                          .join(
                                                                                              ''
                                                                                          )
                                                                                    : '-'}
                                                                            </Typography>
                                                                        </Typography>

                                                                        <Typography
                                                                            variant="body1"
                                                                            component="p"
                                                                            className="fw-600"
                                                                        >
                                                                            Last
                                                                            Modified:
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                            >
                                                                                {row?.updated_at &&
                                                                                    getFormattedDateTime(
                                                                                        row?.updated_at
                                                                                    )}
                                                                            </Typography>
                                                                        </Typography>
                                                                        <Stack className="table-action-button-wrapper">
                                                                            <Link
                                                                                onClick={() =>
                                                                                    navigate(
                                                                                        `/view-profile/${row?.id}`
                                                                                    )
                                                                                }
                                                                                className="cursor-pointer"
                                                                            >
                                                                                {' '}
                                                                                View{' '}
                                                                            </Link>
                                                                            {user?.role !==
                                                                                'user' && (
                                                                                <>
                                                                                    <Typography
                                                                                        variant="body1"
                                                                                        component="span"
                                                                                        className="divider-span"
                                                                                    ></Typography>
                                                                                    <Link
                                                                                        className="cursor-pointer"
                                                                                        onClick={() =>
                                                                                            navigate(
                                                                                                `/edit-profile/${row?.slug}`
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {' '}
                                                                                        Edit{' '}
                                                                                    </Link>
                                                                                    <Typography
                                                                                        variant="body1"
                                                                                        component="span"
                                                                                        className="divider-span"
                                                                                    ></Typography>
                                                                                    <Link
                                                                                        className="cursor-pointer"
                                                                                        onClick={() => {
                                                                                            handleEditDrawer(
                                                                                                row?.id
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        Quick
                                                                                        Edit{' '}
                                                                                    </Link>
                                                                                    <Typography
                                                                                        variant="body1"
                                                                                        component="span"
                                                                                        className="divider-span"
                                                                                    ></Typography>
                                                                                    <Link
                                                                                        className="trash"
                                                                                        onClick={() => {
                                                                                            setSelectedSlugs(
                                                                                                [
                                                                                                    ...selectedSlugs,
                                                                                                    row?.id,
                                                                                                ]
                                                                                            );
                                                                                            trashModalOpen();
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        Trash{' '}
                                                                                    </Link>
                                                                                </>
                                                                            )}
                                                                        </Stack>
                                                                    </Stack>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={7}
                                                    className="table-checkbox-width"
                                                    align="center"
                                                >
                                                    No Record Found
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <Box className="pagination-wrapper">
                            <Box className="item-page-select">
                                <Typography variant="body1" component="span">
                                    Items per page
                                </Typography>
                                <Select
                                    defaultValue={pagintaionPageCount?.find(
                                        option => option.value === showPerPage
                                    )}
                                    menuPlacement="auto"
                                    className={'select-ui'}
                                    classNamePrefix="select"
                                    onChange={selectedOption =>
                                        handleSelectChange(
                                            selectedOption,
                                            setShowPerPage,
                                            fetchData
                                        )
                                    }
                                    options={pagintaionPageCount}
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                />
                            </Box>
                            <Pagination
                                selectedPage={selectedPage}
                                totalPage={totalPage}
                                setSelectedPage={setSelectedPage}
                            />
                        </Box>
                    </CustomTabPanel>
                </Box>
            </Box>

            <Modal
                open={open}
                onClose={trashModalClose}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
            >
                <Fade in={open}>
                    <Box className="modal-wrapper modal-ui">
                        <Box className="modal-body">
                            <Box className="confirmation-message-wrapper">
                                <Box className="image-wrapper">
                                    <img src={DeleteTrash} />
                                </Box>
                                <Box className="confirm-content-wrapper">
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        className="confirm-title"
                                    >
                                        Are you sure want to delete this
                                        profile?
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        component="label"
                                        className="confirm-para"
                                    >
                                        This action is permanent and cannot be
                                        undone.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className="modal-footer-wrapper">
                                <Button
                                    className="btn red-btn"
                                    onClick={() => deleteProfileBySlug()}
                                >
                                    Delete
                                </Button>
                                <Button
                                    className="btn secondary-btn"
                                    onClick={trashModalClose}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
            <Drawer
                className="side-drawer quick-edit-drawer"
                anchor="right"
                open={isOpen}
                onClose={() => isSetOpen(false)}
            >
                <Box className="quick-edit-form-wrapper">
                    <Box className="quick-edit-title-wrapper">
                        <Box className="title">Quick Edit</Box>
                        <Box
                            className="close-btn"
                            onClick={() => isSetOpen(false)}
                        >
                            <HighlightOffRoundedIcon />
                        </Box>
                    </Box>
                    {isDetailLoading ? (
                        <Box className="h-100vh">
                            <Loader />
                        </Box>
                    ) : (
                        <>
                            <Formik
                                initialValues={{
                                    title: profileDetail
                                        ? profileDetail?.title
                                        : '',
                                    slug: profileDetail
                                        ? profileDetail?.slug
                                        : '',
                                    category_name:
                                        Object.keys(profileDetail).length > 0 &&
                                        profileDetail.category_name?.[0]?.id
                                            ? [
                                                  profileDetail
                                                      .category_name?.[0]?.id,
                                              ]
                                            : [],
                                    author: '',
                                    addr_state: profileDetail
                                        ? profileDetail?.address?.addr_state
                                        : '',
                                    meta_title: profileDetail
                                        ? profileDetail?.meta_title
                                        : '',
                                    meta_description: profileDetail
                                        ? profileDetail?.meta_description
                                        : '',
                                    meta_keywords: profileDetail
                                        ? profileDetail?.meta_keywords
                                        : '',
                                    show_contact_form: profileDetail
                                        ? profileDetail?.info?.show_contact_form
                                        : true,
                                    claim_status: profileDetail
                                        ? profileDetail?.info?.claim_status
                                        : true,
                                    disable_adsense: profileDetail
                                        ? profileDetail?.info?.disable_adsense
                                        : false,
                                    comment_status: profileDetail
                                        ? profileDetail?.info?.comment_status
                                        : true,
                                }}
                                validationSchema={validationSchema}
                                onSubmit={(
                                    values,
                                    { setSubmitting, resetForm }
                                ) => {
                                    saveProfileDetail(values);
                                    resetForm();
                                    setSubmitting(false);
                                }}
                                enableReinitialize
                            >
                                {({
                                    values,
                                    isSubmitting,
                                    setFieldValue,
                                    handleChange,
                                }) => (
                                    <Form>
                                        <Box className="drawer-padding">
                                            <Grid container spacing={3}>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Box className="field-wrapper">
                                                        <Typography
                                                            variant="body1"
                                                            component="label"
                                                        >
                                                            Title
                                                        </Typography>
                                                        <Field
                                                            as={TextField}
                                                            className="input-ui"
                                                            placeholder="Enter Title"
                                                            fullWidth
                                                            value={values.title}
                                                            name="title"
                                                        />
                                                        <ErrorMessage
                                                            name="title"
                                                            component="div"
                                                            className="error-message"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Box className="field-wrapper">
                                                        <Typography
                                                            variant="body1"
                                                            component="label"
                                                        >
                                                            Slug
                                                        </Typography>
                                                        <Field
                                                            as={TextField}
                                                            className="input-ui"
                                                            placeholder="Enter Slug"
                                                            fullWidth
                                                            value={values.slug}
                                                            name="slug"
                                                        />
                                                        <ErrorMessage
                                                            name="slug"
                                                            component="div"
                                                            className="error-message"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Box className="field-wrapper">
                                                        <Typography
                                                            variant="body1"
                                                            component="label"
                                                        >
                                                            Category
                                                        </Typography>
                                                        <Select
                                                            menuPlacement="auto"
                                                            className={
                                                                'select-ui'
                                                            }
                                                            classNamePrefix="select"
                                                            options={
                                                                categoryOption
                                                            }
                                                            isLoading={
                                                                categoryLoader
                                                            }
                                                            placeholder="Select Category"
                                                            components={{
                                                                IndicatorSeparator:
                                                                    () => null,
                                                            }}
                                                            isSearchable={true}
                                                            onInputChange={
                                                                handleInputChange
                                                            }
                                                            onChange={selectedOptions => {
                                                                const selectedValue =
                                                                    selectedOptions
                                                                        ? selectedOptions.value
                                                                        : '';
                                                                setFieldValue(
                                                                    'category_name',
                                                                    [
                                                                        selectedValue,
                                                                    ]
                                                                );
                                                            }}
                                                            value={categoryOption?.find(
                                                                option =>
                                                                    option.value ===
                                                                    values
                                                                        ?.category_name?.[0]
                                                            )}
                                                        />
                                                        <ErrorMessage
                                                            name="category_name"
                                                            component="div"
                                                            className="error-message"
                                                        />
                                                    </Box>
                                                </Grid>
                                                {/* <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Box className="field-wrapper">
                                                        <Typography
                                                            variant="body1"
                                                            component="label"
                                                        >
                                                            Author
                                                        </Typography>
                                                        <Select
                                                            menuPlacement="auto"
                                                            className={
                                                                'select-ui'
                                                            }
                                                            classNamePrefix="select"
                                                            options={
                                                                authorOption
                                                            }
                                                            placeholder="Select Author"
                                                            components={{
                                                                IndicatorSeparator:
                                                                    () => null,
                                                            }}
                                                            isLoading={
                                                                categoryLoader
                                                            }
                                                            onChange={selectedOptions => {
                                                                const selectedValues =
                                                                    selectedOptions?.value;
                                                                setFieldValue(
                                                                    'author',
                                                                    selectedValues
                                                                );
                                                            }}
                                                            value={categoryOption?.filter(
                                                                option =>
                                                                    values?.author?.includes(
                                                                        option?.value
                                                                    )
                                                            )}
                                                        />
                                                    </Box>
                                                </Grid> */}
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Box className="field-wrapper">
                                                        <Typography
                                                            variant="body1"
                                                            component="label"
                                                        >
                                                            State
                                                        </Typography>
                                                        <Select
                                                            menuPlacement="auto"
                                                            className={
                                                                'select-ui'
                                                            }
                                                            classNamePrefix="select"
                                                            options={
                                                                stateOption
                                                            }
                                                            placeholder="Select State"
                                                            isLoading={
                                                                StateLoader
                                                            }
                                                            components={{
                                                                IndicatorSeparator:
                                                                    () => null,
                                                            }}
                                                            onChange={selectedOptions => {
                                                                const selectedValues =
                                                                    selectedOptions?.value;
                                                                setFieldValue(
                                                                    'addr_state',
                                                                    selectedValues
                                                                );
                                                            }}
                                                            value={stateOption?.filter(
                                                                option =>
                                                                    values?.addr_state?.includes(
                                                                        option?.value
                                                                    )
                                                            )}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box className="drawer-divider"></Box>
                                        <Box className="drawer-padding">
                                            <Grid container spacing={3}>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Box className="field-wrapper">
                                                        <Typography
                                                            variant="body1"
                                                            component="label"
                                                        >
                                                            Meta Title
                                                        </Typography>
                                                        <Field
                                                            as={TextField}
                                                            className="input-ui"
                                                            placeholder="Enter Meta Title"
                                                            fullWidth
                                                            value={
                                                                values.meta_title
                                                            }
                                                            name="meta_title"
                                                        />
                                                        <ErrorMessage
                                                            name="meta_title"
                                                            component="div"
                                                            className="error-message"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Box className="field-wrapper">
                                                        <Typography
                                                            variant="body1"
                                                            component="label"
                                                        >
                                                            Meta Description
                                                        </Typography>
                                                        <Field
                                                            as={
                                                                TextareaAutosize
                                                            }
                                                            className="textarea-ui"
                                                            placeholder="Enter Meta Description"
                                                            fullWidth
                                                            value={
                                                                values.meta_description
                                                            }
                                                            name="meta_description"
                                                        />
                                                        <ErrorMessage
                                                            name="meta_description"
                                                            component="div"
                                                            className="error-message"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Box className="field-wrapper">
                                                        <Typography
                                                            variant="body1"
                                                            component="label"
                                                        >
                                                            Meta Keywords
                                                        </Typography>
                                                        <Field
                                                            as={TextField}
                                                            className="input-ui"
                                                            placeholder="Enter Meta Keywords"
                                                            fullWidth
                                                            value={
                                                                values.meta_keywords
                                                            }
                                                            name="meta_keywords"
                                                        />
                                                        <ErrorMessage
                                                            name="name"
                                                            component="div"
                                                            className="error-message"
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box className="drawer-divider"></Box>
                                        <Box className="drawer-padding">
                                            <Grid container spacing={3}>
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Box className="field-wrapper switch-field-wrapper">
                                                        <Typography
                                                            variant="body1"
                                                            component="label"
                                                        >
                                                            Show Contact Form
                                                        </Typography>
                                                        <SwitchField
                                                            checked={
                                                                values.show_contact_form
                                                            }
                                                            onChange={value =>
                                                                handleSwitchChange(
                                                                    'show_contact_form',
                                                                    value,
                                                                    setFieldValue
                                                                )
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Box className="field-wrapper switch-field-wrapper">
                                                        <Typography
                                                            variant="body1"
                                                            component="label"
                                                        >
                                                            Profile Claimed
                                                        </Typography>
                                                        <SwitchField
                                                            checked={
                                                                values.claim_status
                                                            }
                                                            onChange={value =>
                                                                handleSwitchChange(
                                                                    'claim_status',
                                                                    value,
                                                                    setFieldValue
                                                                )
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Box className="field-wrapper switch-field-wrapper">
                                                        <Typography
                                                            variant="body1"
                                                            component="label"
                                                        >
                                                            Disable Adsense
                                                        </Typography>
                                                        <SwitchField
                                                            checked={
                                                                values.disable_adsense
                                                            }
                                                            onChange={value =>
                                                                handleSwitchChange(
                                                                    'disable_adsense',
                                                                    value,
                                                                    setFieldValue
                                                                )
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Box className="field-wrapper switch-field-wrapper">
                                                        <Typography
                                                            variant="body1"
                                                            component="label"
                                                        >
                                                            Allow
                                                            Comments/Reviews
                                                        </Typography>
                                                        <SwitchField
                                                            checked={
                                                                values.comment_status
                                                            }
                                                            onChange={value =>
                                                                handleSwitchChange(
                                                                    'comment_status',
                                                                    value,
                                                                    setFieldValue
                                                                )
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box className="drawer-footer-wrapper">
                                            <Box className="btn-wrapper">
                                                <Button
                                                    className="btn primary-btn"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                    endIcon={
                                                        isSubmitting && (
                                                            <CircularProgress
                                                                sx={{
                                                                    color: 'var(--white-color)',
                                                                }}
                                                                size={16}
                                                            />
                                                        )
                                                    }
                                                >
                                                    Save
                                                </Button>
                                                <Button
                                                    className="btn secondary-btn"
                                                    onClick={() =>
                                                        isSetOpen(false)
                                                    }
                                                >
                                                    Cancel
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        </>
                    )}
                </Box>
            </Drawer>
        </>
    );
};

export default Profiles;
