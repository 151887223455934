import { tokenapi } from './api';

//User
export const getUsers = (status= "all",page = 1, limit = 10, search = '', sortBy) => {
    if (sortBy) {
        return tokenapi.get(
            `/users/list/${status}?page=${page}&limit=${limit}&search=${search}&sortby=${sortBy}`
        );
    } else {
        return tokenapi.get(
            `/users/list/${status}?page=${page}&limit=${limit}&search=${search}`
        );
    }
};

export const updateProfile = (data) =>
    tokenapi.put(`/users/detail`, data);
export const getUserDetail = () => tokenapi.get(`/users/detail`);
export const updateUser = (id, data) =>
    tokenapi.put(`/users/details/${id}`, data);
export const addUser = (data) =>
    tokenapi.post(`/users/register`, data);
export const changePassword = data =>
    tokenapi.put(`/users/change-password`, data);
export const deleteUser = id =>
    tokenapi.delete(`/users/details/${id}?delete=true`);
export const getUserById = id => tokenapi.get(`/users/details/${id}`);

//Companies
export const getCompanies = (page = 1, limit = 10, search = '', sortBy) => {
    if (sortBy) {
        return tokenapi.get(
            `/dashboard/companies?page=${page}&limit=${limit}&search=${search}&sortby=${sortBy}`
        );
    } else {
        return tokenapi.get(
            `/dashboard/companies?page=${page}&limit=${limit}&search=${search}`
        );
    }
};
export const getCompanyList = () => tokenapi.get(`/dashboard/companies`);
export const addCompany = data => tokenapi.post('/dashboard/companies', data);
export const getCompanyDetailById = slug =>
    tokenapi.get(`/dashboard/companies/${slug}`);
export const editCompany = (slug, data) =>
    tokenapi.put(`/dashboard/companies/${slug}`, data);
export const deleteCompany = (ids) =>
    tokenapi.delete(`/dashboard/companies?delete=true&ids=${ids}`);

//Categories
export const getCategories = (page = 1, limit = 10, search = '', sortBy) => {
    if (sortBy) {
        return tokenapi.get(
            `/dashboard/categories?page=${page}&limit=${limit}&search=${search}&sortby=${sortBy}`
        );
    } else {
        return tokenapi.get(
            `/dashboard/categories?page=${page}&limit=${limit}&search=${search}`
        );
    }
};
export const getCategoryList = (search = '', page = 1, limit = 20) => tokenapi.get(`/dashboard/categories?page=${page}&limit=${limit}&search=${search}`);
export const addCategory = data => tokenapi.post('/dashboard/categories', data);
export const getCategoryDetailBySlug = slug =>
    tokenapi.get(`/dashboard/categories/${slug}`);
export const editCategory = (slug, data) =>
    tokenapi.put(`/dashboard/categories/${slug}`, data);
export const deleteCategory = (ids) =>
    tokenapi.delete(`/dashboard/categories?delete=true&ids=${ids}`);

//State
export const getStates = (page = 1, limit = 10, search = '', sortBy) => {
    if (sortBy) {
        return tokenapi.get(
            `/dashboard/states?page=${page}&limit=${limit}&search=${search}&sortby=${sortBy}`
        );
    } else {
        return tokenapi.get(
            `/dashboard/states?page=${page}&limit=${limit}&search=${search}`
        );
    }
};
export const editState = (slug, data) =>
    tokenapi.put(`/dashboard/states/${slug}`, data);
export const getStateList = () => tokenapi.get(`/dashboard/states`);
export const deleteState = (ids) =>
    tokenapi.delete(`/dashboard/states?delete=true&ids=${ids}`);


//Country
export const getCountries = (page = 1, limit = 10, search = '', sortBy) => {
    if (sortBy) {
        return tokenapi.get(
            `/dashboard/counties?page=${page}&limit=${limit}&search=${search}&sortby=${sortBy}`
        );
    } else {
        return tokenapi.get(
            `/dashboard/counties?page=${page}&limit=${limit}&search=${search}`
        );
    }
};
export const addCountry = data => tokenapi.post('/dashboard/counties', data);
export const getCountryList = () => tokenapi.get(`/dashboard/counties`);
export const deleteCounty = (ids) =>
    tokenapi.delete(`/dashboard/counties?delete=true&ids=${ids}`);


//Profile
export const getProfiles = (
    status = 'all',
    page = 1,
    limit = 10,
    search = '',
    sortBy
) => {
    if (sortBy) {
        return tokenapi.get(
            `/dashboard/profiles/${status}?page=${page}&limit=${limit}&search=${search}&sortby=${sortBy}`
        );
    } else {
        return tokenapi.get(
            `/dashboard/profiles/${status}?page=${page}&limit=${limit}&search=${search}`
        );
    }
};

export const addProfile = data =>
    tokenapi.post('/dashboard/profiles/new', data);
export const getProfileDetailBySlug = slug =>
    tokenapi.get(`/dashboard/profiles/details/${slug}`);
export const editProfile = (slug, data) =>
    tokenapi.put(`/dashboard/profiles/details/${slug}`, data);
export const deleteProfile = slug =>
    tokenapi.delete(`/dashboard/profiles/details/${slug}?delete=true`);

//Form-entry
export const getFormEntries = (
    form = 'contact_us',
    page = 1,
    limit = 10,
    search = '',
    sortBy
) => {
    if (sortBy) {
        return tokenapi.get(
            `/dashboard/forms/entries/${form}?page=${page}&limit=${limit}&search=${search}&sortby=${sortBy}`
        );
    } else {
        return tokenapi.get(
            `/dashboard/forms/entries/${form}?page=${page}&limit=${limit}&search=${search}`
        );
    }
};
export const getFormEntryById = ( form = 'contact_us',id) => tokenapi.get(`/dashboard/forms/entries/${form}/${id}`);
export const deleteFormEntries = (form,ids) =>
    tokenapi.delete(`/dashboard/forms/entries/${form}?delete=true&ids=${ids}`);

//Plan
export const getPlans = (
    status = 'all',
    page = 1,
    limit = 10,
    search = '',
    sortBy
) => {
    if (sortBy) {
        return tokenapi.get(
            `/dashboard/plans/list/${status}?page=${page}&limit=${limit}&search=${search}&sortby=${sortBy}`
        );
    } else {
        return tokenapi.get(
            `/dashboard/plans/list/${status}?page=${page}&limit=${limit}&search=${search}`
        );
    }
};

export const addPlan = data => tokenapi.post('/dashboard/plan/new', data);
export const getPlanById = id => tokenapi.get(`/dashboard/plan/${id}`);
export const editPlan = (id, data) =>
    tokenapi.put(`/dashboard/plan/${id}`, data);
export const deletePlan = id =>
    tokenapi.delete(`/dashboard/plan/${id}?delete=true`);

//Order-list
export const getOrders = (
    status = '',
    page = 1,
    limit = 10,
    search = '',
    sortBy
) => {
    const queryParams = [];

    if (status) queryParams.push(`status=${status}`);
    if (page) queryParams.push(`page=${page}`);
    if (limit) queryParams.push(`limit=${limit}`);
    if (search) queryParams.push(`search=${search}`);
    if (sortBy) queryParams.push(`sortby=${sortBy}`);

    const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';

    return tokenapi.get(`/dashboard/orders/list${queryString}`);
};
export const getOrderById = id => tokenapi.get(`/dashboard/order/details/${id}`);
export const cancelSubscription = data => tokenapi.post('/dashboard/subscription/cancel', data);


