import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import useMediaQuery from '@mui/material/useMediaQuery';
import createTheme from '@mui/material/styles/createTheme';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import CreateIcon from '@mui/icons-material/Create';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteTrash from '../../assets/images/icons/delete.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Pagination from '../../components/common/pagination';
import { handleSelectChange, pagintaionPageCount } from '../../utils/constants';
import { useDebounce } from '../../utils/useDebounce';
import { deletePlan, getPlans } from '../../utils/apiConstants';
import { toast } from 'react-toastify';
import Loader from '../../components/common/loader';
import {
    ArrowDownward,
    ArrowUpward,
    SwapVert ,
} from '@mui/icons-material';
// TABBING UI FOR TABLE
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const theme = createTheme();

const Plans = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const [loader, setLoader] = useState(true);
    const [totalPage, setTotalPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [totalAllCount, setTotalAllCount] = useState(0);
    const [showPerPage, setShowPerPage] = useState(10);
    const [plans, setPlans] = useState([]);
    const [statusCounts, setStatusCounts] = useState({});
    const [selectedPage, setSelectedPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const debouncedSearchTerm = useDebounce(searchValue, 500);
    const [selectedId, setSelectedId] = useState([]);
    const [headerChecked, setHeaderChecked] = useState(false);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortBy, setSortBy] = useState(null);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getStatus = value => {
        switch (value) {
            case 1:
                return 'active';
            case 2:
                return 'inactive';
            default:
                return 'all';
        }
    };

    const handleHeaderCheckboxChange = () => {
        if (headerChecked) {
            setSelectedId([]);
        } else {
            const allId = plans?.map(item => item?.id);
            setSelectedId(allId);
        }
        setHeaderChecked(!headerChecked);
    };

    const handleRowCheckboxChange = id => {
        if (selectedId.includes(id)) {
            setSelectedId(selectedId.filter(item => item !== id));
        } else {
            setSelectedId([...selectedId, id]);
        }
    };

    const [open, setOpen] = useState(false);
    const trashModalOpen = () => setOpen(true);
    const trashModalClose = () => setOpen(false);

    const [openRow, setOpenRow] = useState(null);
    const handleRowClick = index => {
        setOpenRow(prevOpenRow => (prevOpenRow === index ? null : index));
    };

    const fetchData = pageSize => {
        setSelectedPage(1)
        setShowPerPage(pageSize);
    };

    const getPlanList = async (newSortOrder = sortOrder,column = sortBy) => {
        setLoader(true);
        try {
            const response = await getPlans(
                getStatus(value),
                selectedPage > 0 ? selectedPage : 1,
                showPerPage,
                searchValue,
                sortBy || column ? (newSortOrder || sortOrder)  === 'asc' ? column || sortBy : `-`+column || sortBy : sortBy
            );
            if (response?.status == 200) {
                setPlans(response?.data?.results?.data);
                setTotalCount(response?.data?.count || 0);
                setTotalAllCount(response?.data?.results?.total_counts || 0);
                setStatusCounts(response?.data?.results?.status_count);
                setTotalPage(
                    Math.ceil(response?.data?.count / showPerPage) || 0
                );
            }
        } catch (error) {
            toast.error(error?.response?.data?.detail || error?.response?.data?.message || error?.message);
        }
        setLoader(false);
    };


    const handleSort = (column) => {
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
        getPlanList(newSortOrder,column);
    };

    const getSortIcon = (column) => {
        if (sortOrder === 'asc' && sortBy === column) {
            return <ArrowUpward size={20} />;
        } else if (sortOrder === 'desc' && sortBy === column) {
            return <ArrowDownward size={20} />;
        } else {
            return <SwapVert size={20} />;
        }
    };
    const deletePlanById = async () => {
        setLoader(true);
        try {
            if (selectedId) {
                const promises = selectedId.map(async id => {
                    const response = await deletePlan(id);
                    return response;
                });
                const responses = await Promise.all(promises);

                if (responses?.[0]?.data?.status == 200) {
                    toast.success(responses?.[0]?.data?.message);
                    setSelectedPage(1);
                    getPlanList();
                    setHeaderChecked(false)
                    setOpen(false)
                }
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.response?.data?.error);
        }
        setLoader(false);
    };

    useEffect(() => {
        getPlanList();
    }, [debouncedSearchTerm, showPerPage, selectedPage, value]);

    const featuresOption = option => {
        let features;
        if (
            option?.startsWith("[") &&
            option?.endsWith("]")
          ) {
            features = option
              ?.slice(1, -1)
              ?.replace(/['"]/g, "")
              ?.replace(/\\\//g, "/")
              ?.split(",")
              ?.map((feature) => feature?.trim());
          } else {
            features = option
              ?.split(",")?.map((feature) => feature?.trim());
          }
      

        return features?.map(feature => {
            const trimmedFeature = feature.trim();
            return {
                value: trimmedFeature,
                label: trimmedFeature,
            };
        });
    };
    return (
        <>
            <Box className="content-header plan-module-wrapper">
                <Box className="content-header-left">
                    <Typography variant="h2" component="h2">
                        Plans
                    </Typography>
                    <Button
                        className="outline-btn btn"
                        onClick={() => navigate('/add-plan')}
                    >
                        Add New
                    </Button>
                </Box>
                <Box className="content-header-right">
                    <Box className="item-badge">{totalCount} items</Box>
                    <Box variant="div" component="div" className="search-bar">
                        <SearchIcon />
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Search Plan"
                            onChange={e => {
                                setSearchValue(e.target.value);setSelectedPage(1)
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box className="content-layout">
                <Box className="tab-ui-wrapper">
                    <Tabs
                        className="tab-button-wrapper"
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        aria-label="basic tabs example"
                    >
                        <Tab
                            label={`All  (${totalAllCount || 0})`}
                            {...a11yProps(0)}
                        />
                        <Tab
                            label={`Active (${statusCounts?.active || 0})`}
                            {...a11yProps(1)}
                        />
                        <Tab
                            label={`Inactive (${statusCounts?.inactive || 0})`}
                            {...a11yProps(2)}
                        />
                    </Tabs>
                    <CustomTabPanel value={value} index={value}>
                        <Box className="card-wrapper border-1-5">
                            <TableContainer className="plan-table-ui">
                                <Table stickyHeader aria-label="simple table">
                                    <TableHead className="bg-primary-10">
                                        <TableRow className="hide-thead">
                                            <TableCell className="table-checkbox-width">
                                                <Checkbox
                                                    className="checkbox-ui"
                                                    checked={headerChecked}
                                                    onChange={
                                                        handleHeaderCheckboxChange
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell onClick={()=>{setSortBy('name');handleSort('name')}}>Title {getSortIcon('name')}</TableCell>
                                            <TableCell onClick={()=>{setSortBy('price');handleSort('price')}}>Price {getSortIcon('price')}</TableCell>
                                            <TableCell onClick={()=>{setSortBy('type');handleSort('type')}}>Type {getSortIcon('type')}</TableCell>
                                            <TableCell onClick={()=>{setSortBy('is_active');handleSort('is_active')}}>Status {getSortIcon('is_active')}</TableCell>
                                            <TableCell>Features</TableCell>
                                            {/* <TableCell>Payment URL</TableCell> */}
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loader ? (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={7}
                                                    align={'center'}
                                                >
                                                    <Loader />
                                                </TableCell>
                                            </TableRow>
                                        ) : plans?.length > 0 ? (
                                            plans?.map((row, index) => {
                                                const options = featuresOption(
                                                    row?.features
                                                );
                                                return (
                                                    <React.Fragment key={index}>
                                                        <TableRow
                                                            onClick={e => {
                                                                if (
                                                                    isSmallScreen &&
                                                                    e.target
                                                                        .type !==
                                                                        'checkbox'
                                                                ) {
                                                                    handleRowClick(
                                                                        index
                                                                    );
                                                                }
                                                            }}
                                                            className={
                                                                openRow ===
                                                                index
                                                                    ? 'open'
                                                                    : ''
                                                            }
                                                        >
                                                            <TableCell className="table-checkbox-width">
                                                                <Checkbox
                                                                    className="checkbox-ui"
                                                                    checked={selectedId.includes(
                                                                        row?.id
                                                                    )}
                                                                    onChange={() =>
                                                                        handleRowCheckboxChange(
                                                                            row?.id
                                                                        )
                                                                    }
                                                                />
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                {' '}
                                                                <Box className="show-text-arrow-content">
                                                                    {' '}
                                                                    {
                                                                        row?.title
                                                                    }{' '}
                                                                    {openRow ===
                                                                    index ? (
                                                                        <KeyboardArrowUpIcon />
                                                                    ) : (
                                                                        <KeyboardArrowDownIcon />
                                                                    )}
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                                className="hide-td"
                                                            >
                                                                {row?.price}
                                                            </TableCell>
                                                            <TableCell className="hide-td">
                                                                {row?.type === 'seo' ? 'SEO' : row?.type === 'premium' ? 'Premium' : ''}
                                                            </TableCell>
                                                            <TableCell className="hide-td">
                                                                <Box
                                                                    className={`status ${
                                                                        row.is_active ===
                                                                        true
                                                                            ? 'green'
                                                                            : row.status ===
                                                                              false
                                                                            ? 'dark'
                                                                            : 'yellow'
                                                                    }`}
                                                                >
                                                                    {row?.is_active === true
                                                                        ? 'Active'
                                                                        : 'Inactive'}
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell className="hide-td">
                                                                <Select
                                                                    defaultValue={
                                                                        options
                                                                            ? options[0]
                                                                            : null
                                                                    }
                                                                    menuPlacement="auto"
                                                                    className="select-ui"
                                                                    classNamePrefix="select"
                                                                    menuPosition="fixed"
                                                                    options={
                                                                        options
                                                                    }
                                                                    components={{
                                                                        IndicatorSeparator:
                                                                            () =>
                                                                                null,
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            {/* <TableCell className="hide-td">
                                                                {
                                                                    row?.payment_url
                                                                }
                                                            </TableCell> */}
                                                            <TableCell className="hide-td">
                                                                <Stack
                                                                    direction={
                                                                        'row'
                                                                    }
                                                                    gap={3}
                                                                    className="action-wrapper"
                                                                >
                                                                    <Button
                                                                        className="btn edit-btn"
                                                                        onClick={() =>
                                                                            navigate(
                                                                                `/edit-plan/${row?.id}`
                                                                            )
                                                                        }
                                                                    >
                                                                        <CreateIcon />
                                                                    </Button>
                                                                    <Button
                                                                        className="btn delete-btn"
                                                                        onClick={() => {
                                                                            setSelectedId(
                                                                                [
                                                                                    ...selectedId,
                                                                                    row?.id,
                                                                                ]
                                                                            );
                                                                            trashModalOpen();
                                                                        }}
                                                                    >
                                                                        <DeleteForeverIcon />
                                                                    </Button>
                                                                </Stack>
                                                            </TableCell>
                                                        </TableRow>

                                                        {openRow === index && (
                                                            <TableRow className="accordion-content">
                                                                <TableCell
                                                                    colSpan={2}
                                                                >
                                                                    <Box className="accordion-box">
                                                                        <Stack
                                                                            className=""
                                                                            spacing={
                                                                                1
                                                                            }
                                                                        >
                                                                            <Typography
                                                                                variant="body1"
                                                                                component="p"
                                                                                className="fw-600"
                                                                            >
                                                                                Title:
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    component="p"
                                                                                >
                                                                                    {
                                                                                        row?.title
                                                                                    }
                                                                                </Typography>
                                                                            </Typography>
                                                                            <Typography
                                                                                variant="body1"
                                                                                component="p"
                                                                                className="fw-600"
                                                                            >
                                                                                Price:
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    component="p"
                                                                                >
                                                                                    {
                                                                                        row?.price
                                                                                    }
                                                                                </Typography>
                                                                            </Typography>
                                                                            <Typography
                                                                                variant="body1"
                                                                                component="p"
                                                                                className="fw-600"
                                                                            >
                                                                                Type:
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    component="p"
                                                                                >
                                                                                    {row?.type === 'seo' ? 'SEO' : row?.type === 'premium' ? 'Premium' : ''}
                                                                                </Typography>
                                                                            </Typography>
                                                                            <Typography
                                                                                variant="body1"
                                                                                component="p"
                                                                                className="fw-600"
                                                                            >
                                                                                Status:
                                                                                <Box
                                                                                    className={`status ${
                                                                                        row.is_active ===
                                                                                        false
                                                                                            ? 'dark'
                                                                                            : row.is_active ===
                                                                                              true
                                                                                            ? 'green'
                                                                                            : 'dark'
                                                                                    }`}
                                                                                >
                                                                                    {row.is_active
                                                                                        ? 'Active'
                                                                                        : 'Inactive'}
                                                                                </Box>
                                                                            </Typography>
                                                                            <Typography
                                                                                variant="body1"
                                                                                component="p"
                                                                                className="fw-600"
                                                                            >
                                                                                Features:
                                                                                <Select
                                                                                    defaultValue={
                                                                                        options
                                                                                            ? options[0]
                                                                                            : null
                                                                                    }
                                                                                    menuPlacement="auto"
                                                                                    className="select-ui"
                                                                                    classNamePrefix="select"
                                                                                    menuPosition="fixed"
                                                                                    options={
                                                                                        options
                                                                                    }
                                                                                    components={{
                                                                                        IndicatorSeparator:
                                                                                            () =>
                                                                                                null,
                                                                                    }}
                                                                                />
                                                                            </Typography>
                                                                            {/* <Typography
                                                                                variant="body1"
                                                                                component="p"
                                                                                className="fw-600"
                                                                            >
                                                                                Payment
                                                                                URL:
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    component="p"
                                                                                >
                                                                                    {
                                                                                        row?.payment_url
                                                                                    }
                                                                                </Typography>
                                                                            </Typography> */}
                                                                            <Stack
                                                                                direction={
                                                                                    'row'
                                                                                }
                                                                                gap={
                                                                                    3
                                                                                }
                                                                                className="action-wrapper"
                                                                            >
                                                                                <Button
                                                                                    className="btn edit-btn"
                                                                                    onClick={() =>
                                                                                        navigate(
                                                                                            `/edit-plan/${row?.id}`
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <CreateIcon />
                                                                                </Button>
                                                                                <Button
                                                                                    className="btn delete-btn"
                                                                                    onClick={() => {
                                                                                        setSelectedId(
                                                                                            [
                                                                                                ...selectedId,
                                                                                                row?.id,
                                                                                            ]
                                                                                        );
                                                                                        trashModalOpen();
                                                                                    }}
                                                                                >
                                                                                    <DeleteForeverIcon />
                                                                                </Button>
                                                                            </Stack>
                                                                        </Stack>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })
                                        ) : (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={7}
                                                    className="table-checkbox-width"
                                                    align="center"
                                                >
                                                    No Record Found
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <Box className="pagination-wrapper">
                            <Box className="item-page-select">
                                <Typography variant="body1" component="span">
                                    Items per page
                                </Typography>
                                <Select
                                    defaultValue={pagintaionPageCount?.find(
                                        option => option.value === showPerPage
                                    )}
                                    menuPlacement="auto"
                                    className={'select-ui'}
                                    classNamePrefix="select"
                                    onChange={selectedOption =>
                                        handleSelectChange(
                                            selectedOption,
                                            setShowPerPage,
                                            fetchData
                                        )
                                    }
                                    options={pagintaionPageCount}
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                />
                            </Box>
                            <Pagination
                                selectedPage={selectedPage}
                                totalPage={totalPage}
                                setSelectedPage={setSelectedPage}
                            />
                        </Box>
                    </CustomTabPanel>
                </Box>
            </Box>

            <Modal
                open={open}
                onClose={trashModalClose}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
            >
                <Fade in={open}>
                    <Box className="modal-wrapper modal-ui">
                        <Box className="modal-body">
                            <Box className="confirmation-message-wrapper">
                                <Box className="image-wrapper">
                                    <img src={DeleteTrash} />
                                </Box>
                                <Box className="confirm-content-wrapper">
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        className="confirm-title"
                                    >
                                        Are you sure want to delete this plan?
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        component="label"
                                        className="confirm-para"
                                    >
                                        This action is permanent and cannot be
                                        undone.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className="modal-footer-wrapper">
                                <Button className="btn red-btn"   onClick={() => deletePlanById()}>Delete</Button>
                                <Button
                                    className="btn secondary-btn"
                                    onClick={trashModalClose}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default Plans;
